<template>
  <div class="modal is-active">
    <div @click="$router.go(-1)" class="modal-background pointer"></div>
      <div class="modal-card has-rounded-corners">
        <section class="modal-card-body p-4">
          <div class="vg-body">
            <div v-for="item in translations" class="vg-container " :key="item.id">
              <a class="vg-link"  @click="doTranslation(item.code)" :title="item.title">
                <span class="vg-items">
                  <img :alt="item.alt"
                  :src="`${item.url ? item.url : `https://cdn.jsdelivr.net/npm/vue-gtranslate/img/flags/__${item.title}.png`}`"
                  class="vg-flag"/>
                  <span class="vg-text">{{ item.title }}</span>
                </span>
              </a>
            </div>
          </div>
          <div class="container has-text-centered">
            <button @click="$router.go(-1)" class="button is-primary">
            Done
            </button>
          </div>
        </section>
      </div>
  </div>
</template>

<script>
import "vue-gtranslate/translator.css"
import { deleteCookie } from '../utils/utils.ts'

export default {
  name: 'Translation',
  data() {
    return {
      translations: [
        {
          code: "af",
          title: "Afrikaans"
        },
        {
          code: "sq",
          title: "Albanian"
        },
        {
          code: "ar",
          title: "Arabic"
        },
        {
          code: "hy",
          title: "Armenian"
        },
        {
          code: "az",
          title: "Azerbaijani"
        },
        {
          code: "eu",
          title: "Basque"
        },
        {
          code: "be",
          title: "Belarusian"
        },
        {
          code: "bg",
          title: "Bulgarian"
        },
        {
          code: "ca",
          title: "Catalan"
        },
        {
          code: "zh-CN",
          title: "Chinese (Simplified)"
        },
        {
          code: "zh-TW",
          title: "Chinese (Traditional)"
        },
        {
          code: "hr",
          title: "Croatian"
        },
        {
          code: "cs",
          title: "Czech"
        },

        {
          code: "da",
          title: "Danish"
        },
        {
          code: "nl",
          title: "Dutch"
        },
        {
          code: "en",
          title: "English"
        },
        {
          code: "et",
          title: "Estonian"
        },
        {
          code: "tl",
          title: "Filipino"
        },
        {
          code: "fi",
          title: "Finnish"
        },
        {
          code: "fr",
          title: "French"
        },

        {
          code: "de",
          title: "German"
        },
        {
          code: "el",
          title: "Greek"
        },
        {
          code: "hu",
          title: "Hungarian"
        },
        {
          code: "is",
          title: "Icelandic",
          url: "https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FFlags%20for%20Translator%2Ficeland.png?alt=media&token=6b8ed764-f226-4a86-b6b7-b482d6d31f4a"
        },
        {
          code: "id",
          title: "Indonesian"
        },
        {
          code: "ga",
          title: "Irish"
        },
        {
          code: "it",
          title: "Italian"
        },
        {
          code: "ja",
          title: "Japanese"
        },
        {
          code: "ko",
          title: "Korean"
        },
        {
          code: "lt",
          title: "Lithuanian"
        },
        {
          code: "ms",
          title: "Malay"
        },
        {
          code: "no",
          title: "Norwegian"
        },
        {
          code: "pl",
          title: "Polish"
        },
        {
          code: "pt",
          title: "Portuguese"
        },
        {
          code: '"ro',
          title: "Romanian"
        },
        {
          code: "ru",
          title: "Russian"
        },
        {
          code: "es",
          title: "Spanish"
        },
        {
          code: "sv",
          title: "Swedish"
        },
        {
          code: "th",
          title: "Thai"
        },
        {
          code: "tr",
          title: "Turkish"
        },
        {
          code: "uk",
          title: "Ukrainian"
        }
      ]
    }
  },
  computed: {
    translatorReturnPath () {
      return this.$store.state.translatorReturnPath
    },
    currentRouteName () {
      if (this.$route.path === "/") {
        return `${this.$route.path}`;
      } else {
        return `${this.$route.path}/`;
      }
    }
  },
  methods: {
    setCookie(key, value, expiry) {
      const expires = new Date();
      expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
      document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
    },
    doTranslation(code) {
      deleteCookie('googtrans')
      this.$router
      .replace(`${this.translatorReturnPath}#googtrans(${code})`)
      .catch(err => {
        return err
      })
      window.location.reload()
      return false
    }
  }
}
</script>

<style>
.vg-body{
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.vg-container{
  padding: 10px;
  font-size: 20px;
}

.vg-text{
  color: black;
  padding-left:10px;
}

.vg-items{
  display:flex;
  align-items:center;
}

.vg-flag{
  height:40px;
}
</style>
